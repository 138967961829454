var Common;
(function (Common) {
    var NotificationTypeEnum;
    (function (NotificationTypeEnum) {
        NotificationTypeEnum[NotificationTypeEnum["Information"] = 0] = "Information";
        NotificationTypeEnum[NotificationTypeEnum["Error"] = 1] = "Error";
        NotificationTypeEnum[NotificationTypeEnum["Warning"] = 2] = "Warning";
        NotificationTypeEnum[NotificationTypeEnum["Success"] = 3] = "Success";
    })(NotificationTypeEnum = Common.NotificationTypeEnum || (Common.NotificationTypeEnum = {}));
    var NotificationKeyEnum;
    (function (NotificationKeyEnum) {
        NotificationKeyEnum[NotificationKeyEnum["User"] = 0] = "User";
        NotificationKeyEnum[NotificationKeyEnum["Outlet"] = 1] = "Outlet";
        NotificationKeyEnum[NotificationKeyEnum["Organisation"] = 2] = "Organisation";
    })(NotificationKeyEnum = Common.NotificationKeyEnum || (Common.NotificationKeyEnum = {}));
    var ItemStatus;
    (function (ItemStatus) {
        ItemStatus[ItemStatus["Changed"] = 0] = "Changed";
        ItemStatus[ItemStatus["Deleted"] = 1] = "Deleted";
        ItemStatus[ItemStatus["None"] = 2] = "None";
        ItemStatus[ItemStatus["Loading"] = 3] = "Loading";
    })(ItemStatus = Common.ItemStatus || (Common.ItemStatus = {}));
    var FavouritesType;
    (function (FavouritesType) {
        FavouritesType[FavouritesType["List"] = 0] = "List";
        FavouritesType[FavouritesType["Search"] = 1] = "Search";
    })(FavouritesType = Common.FavouritesType || (Common.FavouritesType = {}));
    var FeatureAddOnType;
    (function (FeatureAddOnType) {
        FeatureAddOnType[FeatureAddOnType["None"] = 0] = "None";
        FeatureAddOnType[FeatureAddOnType["ActiveSharing"] = 1] = "ActiveSharing";
        FeatureAddOnType[FeatureAddOnType["LinkSocialSharing"] = 2] = "LinkSocialSharing";
    })(FeatureAddOnType = Common.FeatureAddOnType || (Common.FeatureAddOnType = {}));
    var PanelContents;
    (function (PanelContents) {
        PanelContents[PanelContents["None"] = 0] = "None";
        PanelContents[PanelContents["Previous"] = 1] = "Previous";
        PanelContents[PanelContents["Located"] = 2] = "Located";
    })(PanelContents || (PanelContents = {}));
    var OutputTo;
    (function (OutputTo) {
        OutputTo[OutputTo["Unknown"] = 0] = "Unknown";
        OutputTo[OutputTo["MobileApp"] = 1] = "MobileApp";
        OutputTo[OutputTo["Browser"] = 2] = "Browser";
        OutputTo[OutputTo["Email"] = 3] = "Email";
        OutputTo[OutputTo["SMS"] = 4] = "SMS";
    })(OutputTo = Common.OutputTo || (Common.OutputTo = {}));
    var OutputAs;
    (function (OutputAs) {
        OutputAs[OutputAs["Unknown"] = 0] = "Unknown";
        OutputAs[OutputAs["Link"] = 1] = "Link";
        OutputAs[OutputAs["PDF"] = 2] = "PDF";
    })(OutputAs = Common.OutputAs || (Common.OutputAs = {}));
    var LookingForVolunteer;
    (function (LookingForVolunteer) {
        LookingForVolunteer[LookingForVolunteer["No"] = 0] = "No";
        LookingForVolunteer[LookingForVolunteer["Yes"] = 1] = "Yes";
        LookingForVolunteer[LookingForVolunteer["Maybe"] = 2] = "Maybe";
    })(LookingForVolunteer = Common.LookingForVolunteer || (Common.LookingForVolunteer = {}));
    var AdStatus;
    (function (AdStatus) {
        AdStatus[AdStatus["Pending"] = 0] = "Pending";
        AdStatus[AdStatus["AwaitingApproval"] = 1] = "AwaitingApproval";
        AdStatus[AdStatus["Published"] = 2] = "Published";
        AdStatus[AdStatus["Rejected"] = 3] = "Rejected";
    })(AdStatus = Common.AdStatus || (Common.AdStatus = {}));
    function SetCurrentCouncil(council) {
        window.CurrentCouncil = council;
        $("#hfCouncilId").val(council.CouncilRegionID);
        var template = $("#councilTrailTemplate").render(council);
        var $template = $("<div/>").html(template);
        $(".nofollow", $template).removeAttr("rel");
        template = $template.html();
        $(".council-trail").html(template);
        SetupTooltipsStyling();
        window.StateUrl = council.StateUrl;
    }
    Common.SetCurrentCouncil = SetCurrentCouncil;
    function SetCurrentLocality(locality) {
        window.CurrentCouncil = locality.Council;
        $("#hfCouncilId").val(locality.CouncilRegionID);
        $("#hfLocationId").val(locality.LocalityID);
        var template = $("#councilTrailTemplate").render(locality);
        var $template = $("<div/>").html(template);
        $(".nofollow", $template).removeAttr("rel");
        template = $template.html();
        $(".council-trail").html(template);
        SetupTooltipsStyling();
        window.StateUrl = locality.Council.StateUrl;
    }
    Common.SetCurrentLocality = SetCurrentLocality;
    function LoadPromotions() {
        $("#lstPromos").not('.slick-initialized').slick({
            slidesToShow: $("#lstPromos").attr("data-slick-slidesToShow"),
            slide: $("#lstPromos").attr("data-slick-slideElement"),
            autoplay: true
        });
        $("#lstCouncilPromos").not('.slick-initialized').slick({
            slidesToShow: $("#lstCouncilPromos").attr("data-slick-slidesToShow"),
            slide: $("#lstCouncilPromos").attr("data-slick-slideElement"),
            autoplay: true
        });
        $("#lstCommunityPromos").not('.slick-initialized').slick({
            slidesToShow: $("#lstCommunityPromos").attr("data-slick-slidesToShow"),
            slide: $("#lstCommunityPromos").attr("data-slick-slideElement"),
            autoplay: true
        });
    }
    Common.LoadPromotions = LoadPromotions;
    // ---- Background Images ----
    function LoadBackgroundImages() {
        var section = $('.council-search');
        if (section) {
            var current = section.data('images-start-no');
            var ownerType = section.data('images-ownertype');
            var ownerId = section.data("images-ownerid");
            if (current && ownerType && ownerId) {
                $.getJSON("/api/images/backgrounds/" + ownerType + "/" + ownerId, function (data) {
                    if (data.length <= 1)
                        return;
                    window.BackgroundImages = data;
                    setupBackgroundPager();
                    window.BackgroundTimeout = window.setTimeout(showBackgroundImage, data[current].DisplayTime, current);
                });
            }
        }
    }
    Common.LoadBackgroundImages = LoadBackgroundImages;
    function setupBackgroundPager() {
        $(".bg-pager-link").on('click', function (e) {
            e.preventDefault();
            var next = $(this).data('image-index') - 1;
            if (window.BackgroundTimeout)
                clearTimeout(window.BackgroundTimeout);
            showBackgroundImage(next);
        });
    }
    function showBackgroundImage(current) {
        var next = current + 1;
        if (next >= window.BackgroundImages.length)
            next = 0;
        $('.council-search').css('background-image', 'url(' + window.BackgroundImages[next].ImageURL + ')');
        var template = $('#attributionTemplate').render(window.BackgroundImages[next]);
        var $template = $("<div/>").html(template);
        $(".nofollow", $template).removeAttr("rel");
        template = $template.html();
        $('.image-attribution').html(template);
        $(".bg-pager-link").removeClass('active');
        $(".bg-pager-link[data-image-index='" + next + "']").addClass('active');
        window.BackgroundTimeout = window.setTimeout(showBackgroundImage, window.BackgroundImages[next].DisplayTime, next);
    }
    // ---- End Background Images ----
    function InitCategoryLinks() {
        var ExpandButton = $(".btn-expand-collapse");
        // Extend bootstrap's collapse
        $('body').on('click.collapse-next.data-api', '[data-toggle=collapse-next]', function (e) {
            e.preventDefault();
            var $target = $(this).parent().next();
            var expanded = !$target.is(".collapse.in");
            if ($target.data('bs.collapse')) {
                // Collpase has initialised
                $target.collapse('toggle');
            }
            else {
                // Collapse needs initialising
                $target.collapse();
            }
            $(this).attr("aria-expanded", expanded ? "true" : "false");
        });
        // Whenever a link expands, the 'Expand all' should now show 'collapse all'
        $(".category-link .link-list").on("show.bs.collapse", function () {
            if (ExpandButton.length) {
                if (!ExpandButton.hasClass("btn-collapse-all")) {
                    ExpandButton.addClass("btn-collapse-all");
                    ExpandButton.text("Close all categories");
                    ExpandButton.attr("aria-expanded", "true");
                }
            }
            $(this).prev().find(".category-expand").addClass("category-expanded");
        });
        // Whenever a link set finishes closing, check whether all other groups are closed as well
        $(".category-link .link-list").on("hidden.bs.collapse", function () {
            if (ExpandButton.length) {
                if ($(".link-list:visible").length === 0) {
                    // No more are visible, so update expand all button
                    ExpandButton.removeClass("btn-collapse-all");
                    ExpandButton.text("Expand all categories");
                    ExpandButton.attr("aria-expanded", "false");
                }
            }
            $(this).prev().find(".category-expand").removeClass("category-expanded");
        });
        if (ExpandButton.length) {
            ExpandButton.on("click touchend", function (e) {
                e.preventDefault();
                if ($(this).hasClass("btn-collapse-all")) {
                    // Collapse all relevant items
                    $(".link-list:visible").each(function () {
                        $(this).prev().find(".category-expand").trigger("click");
                    });
                    $(this).text("Expand all categories");
                    $(this).removeClass('btn-collapse-all');
                }
                else {
                    // Expand everything
                    $(".category-expand").each(function () {
                        $(this).trigger("click");
                    });
                }
            });
            ExpandButton.on("keypress", function (e) {
                if (e.which === 13) {
                    e.preventDefault();
                    if ($(this).hasClass("btn-collapse-all")) {
                        // Collapse all relevant items
                        $(".link-list:visible").each(function () {
                            $(this).prev().find(".category-expand").trigger("click");
                        });
                    }
                    else {
                        // Expand everything
                        $(".category-expand").each(function () {
                            $(this).trigger("click");
                        });
                    }
                }
            });
        }
        $(".btn-load-more").on("click touchend", function (e) {
            e.preventDefault();
            var itemsToShow = $(this).data("items-to-show");
            var itemSelector = $(this).data("item-selector");
            var hiddenClass = $(this).data("hidden-class"); // The class used on the items to hide them from display
            var counterToUpdate = $($(this).data("counter-selector")); // May or may not exist
            // Show the next x hidden items based on the attribute
            //console.log($(".category-link:not(:visible)").length);
            $(itemSelector + "." + hiddenClass + ":lt(" + itemsToShow + ")").removeClass(hiddenClass);
            // Update the counters
            if (counterToUpdate.length)
                counterToUpdate.text($(itemSelector + ":not(." + hiddenClass + ")").length);
            // Hide the 'Load More' if it is no longer needed
            if ($(itemSelector + "." + hiddenClass).length === 0)
                $(this).hide();
        });
    }
    Common.InitCategoryLinks = InitCategoryLinks;
    function InitEmail(callback) {
        $("a[href='#contact-form']").click(function (e) {
            e.preventDefault();
            $("#contact-form").show();
            $("#txt-name").focus();
        });
        $('#sendEmail').click(function (e) {
            e.preventDefault();
            $.notifyDefaults({
                delay: 0,
                allow_dismiss: false,
                newest_on_top: true,
                mouse_over: 'pause',
                template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                    '<img data-notify="icon">' +
                    '<div class="content"><span data-notify="title">{1}</span>' +
                    '<span data-notify="message">{2}</span></div>' +
                    '</div>'
            });
            var sending = $.notify({
                title: "Sending ...",
                message: "Please wait while we rewrite your message on to our premium papyrus and load the carrier pidgeon. It will be sent soon ... ",
                icon: "/_static/images/processing.gif"
            }, { icon_type: 'image' });
            $(this).prop('disabled', true);
            var email = callback($(this));
            $.notifyDefaults({
                delay: 5000,
                timer: 500,
                allow_dismiss: true,
                newest_on_top: true,
                mouse_over: 'pause',
                onClosed: function () { $('#sendEmail').prop('disabled', false); },
                template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                    '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                    '<span data-notify="icon"></span>' +
                    '<span data-notify="message">{2}</span></div>' +
                    '<div class="progress" data-notify="progressbar">' +
                    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div>' +
                    '</div>'
            });
            $.ajax({
                type: 'POST',
                url: "/api/Email/Send",
                data: JSON.stringify(email),
                headers: { "x-g-recaptcha-token": $("#g-recaptcha-response").val() },
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    if (data.Success) {
                        sending.close();
                        $.notify({ title: "Success", message: "Your message was successfully sent.", icon: "glyphicon glyphicon-ok-circle" }, { type: "success" });
                        $("#txt-name").val('');
                        $("#txt-email").val('');
                        $("#txt-phone").val('');
                        $("#txt-enquiry").val('');
                        $("#contact-form").hide();
                    }
                    else {
                        sending.close();
                        $.notify({ title: "Ooops", message: "We had the following error in trying to send your message: " + data.Message, icon: "glyphicon glyphicon-remove-circle" }, { type: "warning" });
                    }
                },
                error: function () {
                    sending.close();
                    $.notify({ title: "Oh My", message: "There was an error trying to send your message.", icon: "glyphicon glyphicon-remove-circle" }, { type: "danger" });
                }
            });
        });
    }
    Common.InitEmail = InitEmail;
    // Displays a number with commas separating the thousands
    function AddCommas(val) {
        var strVal = val + '';
        var x = strVal.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }
    Common.AddCommas = AddCommas;
    // TODO
    // + rename all vars that are using 'mobile' to 'phone'
    // 
    // Usage: 
    // 1. In a View (.cshtml), add the following line
    // @Html.Partial("_ShareTool", new ShareFeatureModel(ShareFeatureType.ShareAListing)) <- select a type of Share Feature
    // 2. in a loaded pagescript for the same View, put this line where the page is being loaded/init
    // Common.SetupShareModal($('#ShareModal-ShareAListing')); <- select the modal by id(#) that is generated from the Partial
    function SetupShareModal(modal) {
        // ============================================================================
        var Gender;
        (function (Gender) {
            Gender[Gender["undefined"] = 0] = "undefined";
            Gender[Gender["Male"] = 1] = "Male";
            Gender[Gender["Female"] = 2] = "Female";
            Gender[Gender["Other"] = 3] = "Other";
        })(Gender || (Gender = {}));
        var ShareAs;
        (function (ShareAs) {
            ShareAs[ShareAs["mobileapp"] = 0] = "mobileapp";
            ShareAs[ShareAs["weblink"] = 1] = "weblink";
            ShareAs[ShareAs["pdf"] = 2] = "pdf";
        })(ShareAs || (ShareAs = {}));
        var ShareFeature;
        (function (ShareFeature) {
            ShareFeature[ShareFeature["None"] = 0] = "None";
            ShareFeature[ShareFeature["ShareAListing"] = 1] = "ShareAListing";
            ShareFeature[ShareFeature["ShareNonlisted"] = 2] = "ShareNonlisted";
            ShareFeature[ShareFeature["ShareATile"] = 3] = "ShareATile";
            ShareFeature[ShareFeature["ShareANote"] = 4] = "ShareANote";
            ShareFeature[ShareFeature["RecordCommunication"] = 5] = "RecordCommunication";
            ShareFeature[ShareFeature["ShareFavorites"] = 6] = "ShareFavorites";
            ShareFeature[ShareFeature["ShareQRCode"] = 7] = "ShareQRCode";
        })(ShareFeature || (ShareFeature = {}));
        var ShareMethod;
        (function (ShareMethod) {
            ShareMethod[ShareMethod["None"] = 0] = "None";
            ShareMethod[ShareMethod["Email"] = 1] = "Email";
            ShareMethod[ShareMethod["SMS"] = 2] = "SMS";
            ShareMethod[ShareMethod["PDF"] = 3] = "PDF";
            ShareMethod[ShareMethod["InPerson"] = 4] = "InPerson";
        })(ShareMethod || (ShareMethod = {}));
        // ============================================================================
        var $modal = modal; // Select the modal in the current DOM by id(#), meaning only (first) one will be chosen
        // User Block
        var $userBlock = $modal.find('.user-block');
        var $methodBlock = $modal.find('.method-block');
        var $contentBlock = $modal.find('.info-block');
        var $btnUserAdd = $userBlock.find('.user-add');
        var $searchInput = $userBlock.find('.search-input');
        var $peopleList = $userBlock.find('.people');
        var $userBlock_blockTitle = $userBlock.find('.block-title');
        var $userBlock_blockTitle_back = $userBlock_blockTitle.find('.back');
        var $userBlock_personInfo = $userBlock.find('.person-info');
        var $userBlock_personInfo_FirstName = $userBlock_personInfo.find('input[name="fname"]');
        var $userBlock_personInfo_GivenNames = $userBlock_personInfo.find('input[name="gname"]');
        var $userBlock_personInfo_Email = $userBlock_personInfo.find('input[name="email"]');
        var $userBlock_personInfo_Mobile = $userBlock_personInfo.find('input[name="mobile"]');
        var $userBlock_personInfo_Dob = $userBlock_personInfo.find('input[name="dob"]');
        var $userBlock_personInfo_Gender = $userBlock_personInfo.find('select[name="gender"]');
        var $methodItem_Email = $methodBlock.find('#email');
        var $methodItem_SMS = $methodBlock.find('#sms');
        var $methodItem_PDF = $methodBlock.find('#pdf');
        var $methodItem_InPerson = $methodBlock.find('#inperson');
        var $contentTitle = $contentBlock.find('input[name="title"]');
        var $contentCategoryGroup = $contentBlock.find('select[name="categorygroup"]');
        var $contentMessage = $contentBlock.find('textarea[name="message"]');
        var $contentNonlisted = $contentBlock.find('.nonlisted');
        var $contentNonlisted_Mobile = $contentNonlisted.find('[name="mobile"]');
        //var $contentNonlisted_Email              = $contentNonlisted.find('[name="email"]');
        var $contentNonlisted_Website = $contentNonlisted.find('[name="website"]');
        var $contentTileShare = $contentBlock.find('.tileshare');
        var $contentTileShare_Tile = $contentTileShare.find('[name="tile"]');
        //var $contentTileShare_Street             = $contentTileShare.find('[name="street"]');
        //var $contentTileShare_Suburb             = $contentTileShare.find('[name="suburb"]');
        //var $contentTileShare_Funding            = $contentTileShare.find('.funding');
        //var $contentTileShare_Funding_NDIS       = $contentTileShare_Funding.find('[id="ndis"]');
        //var $contentTileShare_Funding_MyAgedCare = $contentTileShare_Funding.find('[id="myagedcare"]');
        //var $contentTileShare_Funding_NoFunding  = $contentTileShare_Funding.find('[id="nofunding"]');
        //var $contentTileShare_Funding_Assistance = $contentTileShare.find('.assistance-level').find('input[type="radio"][name="assistance"]');
        //var $contentTileShare_Agree              = $contentBlock.find('.tileshare.tileshareagree').find('input');
        var $contentFavoriteShare_ShareAs = $contentBlock.find('select[name="shareas"]');
        var $btnSubmit = $modal.find('button[type="submit"]');
        // State
        var EnablePersonInfoBlock = true;
        var EnableInfoEditable = true;
        var EnableSMSShare = false;
        var SendingSMS = false;
        var EnableEmailShare = false;
        var SendingEmail = false;
        var EnablePDFPrint = false;
        var SendingPDFPrint = false;
        var EnableInPersonShare = false;
        var SendingInPerson = false;
        var _shareMethod = ShareMethod.None;
        var shareState = {
            ShareFeatureType: ShareFeature.None,
            People: [],
            SelectedRecipient: null,
            Content: {
                Title: '',
                Message: '',
                PageUrl: '',
                CategoryGroup: null,
            }
        };
        switch (ShareFeature[$modal.find('input[name="FeatureType"]').val()]) {
            case ShareFeature.ShareAListing:
                shareState.ShareFeatureType = ShareFeature.ShareAListing;
                break;
            case ShareFeature.ShareFavorites: // This doesn't work for now
                shareState.ShareFeatureType = ShareFeature.ShareFavorites;
                shareState.Content.FavoriteShare = {
                    FavoriteID: 0,
                    ShareAs: ShareAs.weblink,
                    FavoriteName: ''
                };
                break;
            case ShareFeature.ShareNonlisted:
                shareState.ShareFeatureType = ShareFeature.ShareNonlisted;
                shareState.Content.CategoryGroup = {
                    ID: 0,
                    Name: ''
                };
                shareState.Content.Nonlisted = {
                    Email: '',
                    Mobile: '',
                    Website: '',
                };
                break;
            case ShareFeature.ShareATile:
                shareState.ShareFeatureType = ShareFeature.ShareATile;
                shareState.Content.TileShare = {
                    Tile: {
                        ID: 0,
                        Name: ''
                    },
                    IsFundedByEmploymentServices: false,
                    IsFundedByMyAgedCare: false,
                    IsFundedByNDIS: false,
                    LinkageAssistanceLevel: 'Low',
                    Street: '',
                    Suburb: '',
                    Agree: true,
                    QR: '',
                };
                break;
            case ShareFeature.RecordCommunication:
                shareState.ShareFeatureType = ShareFeature.RecordCommunication;
                break;
            case ShareFeature.ShareANote:
                shareState.ShareFeatureType = ShareFeature.ShareANote;
                shareState.Content.CategoryGroup = {
                    ID: 0,
                    Name: ''
                };
                break;
            case ShareFeature.ShareQRCode:
                shareState.ShareFeatureType = ShareFeature.ShareQRCode;
                shareState.Content.CategoryGroup = {
                    ID: 0,
                    Name: ''
                };
                break;
            default:
                shareState.ShareFeatureType = ShareFeature.None;
                break;
        }
        var CategoryGroups = [];
        var Tiles = [];
        //
        // Reset
        function EmptyCommonFields() {
            $contentTitle.val('');
            $contentMessage.val('');
            $contentNonlisted.val('');
            $contentNonlisted_Mobile.val('');
            //$contentNonlisted_Email  .val('');
            $contentNonlisted_Website.val('');
            $contentTileShare_Tile.val('');
            //$contentTileShare_Street .val('');
            // Uncheck things too
        }
        function ResetNecessaryStates() {
            shareState.People = [];
            shareState.Content.Title = '';
            shareState.Content.Message = '';
            SendingSMS = false;
            SendingPDFPrint = false;
            SendingEmail = false;
        }
        $modal.on('show.bs.modal', function () {
            var _a;
            $modal.find('.err').hide();
            $modal.find('.err').empty();
            $peopleList.show();
            // these can only be assigned once the page is loaded
            if (shareState.ShareFeatureType == ShareFeature.ShareAListing) { // the title of this type must be the title of the current page
                shareState.Content.Title = document.title;
                $contentTitle.val(shareState.Content.Title);
            }
            shareState.Content.PageUrl = window.location.href;
            if (shareState.ShareFeatureType == ShareFeature.ShareQRCode) {
                shareState.Content.PageUrl = (_a = $("#copyButton").attr('data-url')) !== null && _a !== void 0 ? _a : $('#currentQRCodePageLink').val();
            }
            if ($searchInput && $searchInput.length > 0) { // if is logged in
                $searchInput.find('input').val('');
                $userBlock.find('.err').hide();
                $userBlock.find('.err').empty();
            }
            else {
                if (!$userBlock_personInfo.is(':visible'))
                    TogglePersonInfoBlock(EnablePersonInfoBlock, !EnableInfoEditable);
            }
        });
        $modal.on('hidden.bs.modal', function () {
            $userBlock_blockTitle_back.click();
            $peopleList.empty();
            $peopleList.hide();
            EmptyCommonFields();
            ResetNecessaryStates();
        });
        //
        // Closures
        function UpdateFavoriteShare(model) {
            if (shareState.Content.FavoriteShare && model) {
                shareState.Content.FavoriteShare.FavoriteID = model.FavoriteID;
                shareState.Content.FavoriteShare.FavoriteName = model.FavoriteName;
                shareState.Content.Title = shareState.Content.FavoriteShare.FavoriteName;
                $contentTitle.val(shareState.Content.Title);
            }
        }
        //
        $(document).ready(function () {
            $('.selection-input[name="gender"]').selectize();
            $('.selection-input[name="categorygroup"]').selectize({
                dropdownDirection: 'up' // trivial, doesn't work for now
            });
            $('.selection-input[name="tile"]').selectize({
                dropdownDirection: 'up' // trivial, doesn't work for now
            });
            $('.selection-input[name="shareas"]').selectize({
                dropdownDirection: 'up' // trivial, doesn't work for now
            });
            if ($.fn.datepicker.noConflict) {
                // This is bootstrap-datepicker
                $('.input-group.date').datepicker({
                    format: 'dd.mm.yyyy',
                    endDate: '0d',
                    autoclose: true
                }).on('changeDate', function (ev) {
                    var selectedDate = ev.date;
                    var formattedDate = moment(selectedDate).format('DD.MM.YYYY');
                    $('.input-group.date').val(formattedDate);
                });
            }
            else {
                // This is jQuery UI datepicker
                $('.input-group.date').datepicker({
                    dateFormat: 'dd.mm.yy',
                    maxDate: 0,
                    onClose: function (selectedDate) {
                        var formattedDate = moment(selectedDate, 'DD.MM.YYYY').format('DD.MM.YYYY');
                        $('.input-group.date').val(formattedDate);
                    }
                });
            }
            // Listen for click events on the modal backdrop
            $modal.on('click', function (event) {
                // If the clicked element is the modal's direct child (the backdrop)
                if ($(event.target).hasClass('modal')) {
                    $(this).modal('hide'); // Close the modal
                }
            });
            if (shareState.ShareFeatureType == ShareFeature.RecordCommunication
                || shareState.ShareFeatureType == ShareFeature.ShareNonlisted
                || shareState.ShareFeatureType == ShareFeature.ShareANote) {
                $.ajax({
                    type: "GET",
                    url: window.AppSettings.ApiUrl + "/api/v1/system/categorygroups",
                    cache: false,
                    success: function (res) {
                        if (res.success != true)
                            return;
                        var _data = res.data;
                        if (_data == null || _data.length <= 0)
                            return;
                        _data.forEach(function (cat) {
                            var catgrp = {
                                ID: cat.id,
                                Name: cat.name
                            };
                            CategoryGroups.push(catgrp);
                        });
                        CategoryGroups.forEach(function (e) {
                            var _a, _b;
                            (_b = (_a = $contentCategoryGroup[0]) === null || _a === void 0 ? void 0 : _a.selectize) === null || _b === void 0 ? void 0 : _b.addOption({
                                value: e.ID,
                                text: e.Name
                            });
                        });
                    },
                    error: function (err) {
                    },
                });
            }
            if (shareState.ShareFeatureType == ShareFeature.ShareATile) {
                $.ajax({
                    type: "GET",
                    url: window.AppSettings.ApiUrl + "/api/v1/share/GetShareableTiles",
                    cache: false,
                    success: function (res) {
                        if (res.success != true)
                            return;
                        var _data = res.data;
                        if (_data == null || _data.length <= 0)
                            return;
                        _data.forEach(function (_tile) {
                            var tile = {
                                ID: _tile.ID,
                                Name: _tile.Name
                            };
                            Tiles.push(tile);
                        });
                        Tiles.forEach(function (e) {
                            var _a, _b;
                            (_b = (_a = $contentTileShare_Tile[0]) === null || _a === void 0 ? void 0 : _a.selectize) === null || _b === void 0 ? void 0 : _b.addOption({
                                value: e.ID,
                                text: e.Name
                            });
                        });
                    },
                    error: function (err) {
                    },
                });
            }
        });
        var save_icons = []; // might not need this anymore
        // Custom list storing save icons to toggle
        // [{ element: $save_icon_element, name: 'fname' }, { element: $save_icon_element, name: 'gname' }]
        function AssignFieldValueToRecipient($element) {
            var _a, _b;
            if (shareState.SelectedRecipient) {
                switch ($element.attr('name')) {
                    case 'fname':
                        if (shareState.SelectedRecipient.Hash && $element.val() != shareState.SelectedRecipient.FirstName) {
                        }
                        else {
                            shareState.SelectedRecipient.FirstName = $element.val();
                        }
                        break;
                    case 'gname':
                        if (shareState.SelectedRecipient.Hash && $element.val() != shareState.SelectedRecipient.GivenNames) {
                        }
                        else {
                            shareState.SelectedRecipient.GivenNames = $element.val();
                        }
                        break;
                    case 'email':
                        if (shareState.SelectedRecipient.Hash && $element.val() != shareState.SelectedRecipient.Email) {
                        }
                        else {
                            shareState.SelectedRecipient.Email = $element.val();
                        }
                        break;
                    case 'mobile':
                        if (shareState.SelectedRecipient.Hash && $element.val() != shareState.SelectedRecipient.Mobile) {
                        }
                        else {
                            shareState.SelectedRecipient.Mobile = $element.val();
                        }
                        break;
                    case 'gender':
                        if (shareState.SelectedRecipient.Hash && ((_a = $element[0].selectize) === null || _a === void 0 ? void 0 : _a.getValue()) != shareState.SelectedRecipient.Gender) {
                        }
                        else {
                            shareState.SelectedRecipient.Gender = (_b = $element[0].selectize) === null || _b === void 0 ? void 0 : _b.getValue();
                        }
                        break;
                    case 'dob':
                        if (shareState.SelectedRecipient.Hash && $element.val() != shareState.SelectedRecipient.DoB) {
                        }
                        else {
                            shareState.SelectedRecipient.DoB = $element.val();
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        $userBlock_personInfo_FirstName.on('input change', function () {
            AssignFieldValueToRecipient($(this));
        });
        $userBlock_personInfo_GivenNames.on('input change', function () {
            AssignFieldValueToRecipient($(this));
        });
        $userBlock_personInfo_Dob.on('input change', function () {
            AssignFieldValueToRecipient($(this));
        });
        $userBlock_personInfo_Gender.on('change', function () {
            AssignFieldValueToRecipient($(this));
        });
        $userBlock_personInfo_Email.on('input', function () {
            AssignFieldValueToRecipient($(this));
            UpdateShareableMethods();
        });
        $userBlock_personInfo_Email.on('change', function () {
            AssignFieldValueToRecipient($(this));
            UpdateShareableMethods();
        });
        $userBlock_personInfo_Mobile.on('input', function () {
            AssignFieldValueToRecipient($(this));
            UpdateShareableMethods(true);
        });
        $userBlock_personInfo_Mobile.on('change', function () {
            AssignFieldValueToRecipient($(this));
            UpdateShareableMethods(true);
        });
        $contentTitle.on('input change', function () {
            shareState.Content.Title = $(this).val();
        });
        $contentCategoryGroup.on('change', function () {
            if (shareState.Content.CategoryGroup) {
                shareState.Content.CategoryGroup.ID = $(this).val();
                shareState.Content.CategoryGroup.Name = $(this).text();
            }
        });
        $contentMessage.on('input change', function () {
            shareState.Content.Message = $(this).val();
        });
        function AssignPersonInfoToFields(p) {
            var _a, _b, _c, _d, _e;
            function getGenderName(value) {
                return Gender[value];
            }
            if (p) {
                $userBlock_personInfo_FirstName.val(p.FirstName);
                $userBlock_personInfo_GivenNames.val(p.GivenNames);
                $userBlock_personInfo_Email.val(p.Email);
                $userBlock_personInfo_Mobile.val(p.Mobile);
                $userBlock_personInfo_Dob.val((_a = p.DoB) === null || _a === void 0 ? void 0 : _a.slice(0, -5));
                (_c = (_b = $userBlock_personInfo_Gender[0]) === null || _b === void 0 ? void 0 : _b.selectize) === null || _c === void 0 ? void 0 : _c.setValue(getGenderName(p.Gender));
            }
            else {
                $userBlock_personInfo_FirstName.val('');
                $userBlock_personInfo_GivenNames.val('');
                $userBlock_personInfo_Email.val('');
                $userBlock_personInfo_Mobile.val('');
                $userBlock_personInfo_Dob.val('');
                (_e = (_d = $userBlock_personInfo_Gender[0]) === null || _d === void 0 ? void 0 : _d.selectize) === null || _e === void 0 ? void 0 : _e.clear();
            }
        }
        function TogglePersonInfoBlock(show, toggleEditable, p) {
            function ToggleEditable($parent, _toggle) {
                var _a;
                var $edit = $parent.find('.input-edit');
                var $save_icon = $parent.find('.input-save'); // this is already a (hidden) div in the view
                var _input = $parent.find('input');
                var _genderSelect = (_a = $parent.find('select[name="gender"]')[0]) === null || _a === void 0 ? void 0 : _a.selectize;
                if (p) {
                    _input.prop('disabled', true);
                    if (_genderSelect) {
                        _genderSelect.disable();
                    }
                }
                else {
                    _input.prop('disabled', false);
                    if (_genderSelect) {
                        _genderSelect.enable();
                    }
                }
                $save_icon.hide();
                $edit.on('click', function () {
                    _input.prop('disabled', false);
                    if (_genderSelect) {
                        _genderSelect.enable();
                    }
                    $edit.hide();
                    if (toggleEditable) {
                        if (_genderSelect) {
                            _genderSelect.on('change', function () {
                                shareState.SelectedRecipient.Gender = _genderSelect.getValue();
                            });
                        }
                        else {
                            _input.on('input', function () {
                                switch ($(this).attr('name')) {
                                    case 'fname':
                                        shareState.SelectedRecipient.FirstName = $(this).val();
                                        break;
                                    case 'gname':
                                        shareState.SelectedRecipient.GivenNames = $(this).val();
                                        break;
                                    case 'email':
                                        shareState.SelectedRecipient.Email = $(this).val();
                                        shareState.SelectedRecipient.isNew_Email = true;
                                        UpdateShareableMethods();
                                        break;
                                    case 'mobile':
                                        shareState.SelectedRecipient.Mobile = $(this).val();
                                        shareState.SelectedRecipient.isNew_Mobile = true;
                                        UpdateShareableMethods();
                                        break;
                                    case 'dob':
                                        shareState.SelectedRecipient.DoB = $(this).val();
                                        break;
                                    default:
                                        break;
                                }
                            });
                            _input.on('change', function () {
                                switch ($(this).attr('name')) {
                                    case 'fname':
                                        shareState.SelectedRecipient.FirstName = $(this).val();
                                        break;
                                    case 'gname':
                                        shareState.SelectedRecipient.GivenNames = $(this).val();
                                        break;
                                    case 'email':
                                        shareState.SelectedRecipient.Email = $(this).val();
                                        shareState.SelectedRecipient.isNew_Email = true;
                                        UpdateShareableMethods(true);
                                        break;
                                    case 'mobile':
                                        shareState.SelectedRecipient.Mobile = $(this).val();
                                        shareState.SelectedRecipient.isNew_Mobile = true;
                                        UpdateShareableMethods(true);
                                        break;
                                    case 'dob':
                                        shareState.SelectedRecipient.DoB = $(this).val();
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    }
                });
                if (save_icons.length < 6) { // 6 is fname, gname, email, mobile, gender, dob
                    save_icons.push({ element: $save_icon, name: $parent.find('input, select').first().attr('name') });
                }
                if (_toggle) {
                    $edit.show();
                }
                else {
                    $edit.hide();
                }
            }
            function showElement($e) {
                $e.show();
                $e.css('opacity', '1');
                $e.css('height', 'auto');
            }
            function hideElement($e) {
                $e.hide();
                $e.css('opacity', '0');
                $e.css('height', '0');
            }
            if (show) {
                showElement($userBlock_personInfo);
                var labelledInputs = $userBlock_personInfo.find('.labelled-input');
                labelledInputs.each(function (i, e) {
                    ToggleEditable($(e), toggleEditable);
                });
                if (p) {
                    shareState.SelectedRecipient = p;
                }
                else {
                    shareState.SelectedRecipient = {
                        FirstName: '',
                        GivenNames: '',
                        Email: '',
                        Mobile: '',
                        Gender: Gender.undefined,
                        DoB: '',
                        idx: -1
                    };
                }
                AssignPersonInfoToFields(shareState.SelectedRecipient);
            }
            else {
                shareState.SelectedRecipient = null; // refactor this so the recipient info is kept
                hideElement($userBlock_personInfo);
            }
        }
        $userBlock_blockTitle_back.on('click', function () {
            $userBlock_blockTitle_back.hide();
            $searchInput.show(); // Show the search again
            $peopleList.show(); // Show the last search results
            $peopleList.empty();
            shareState.People = [];
            $searchInput.find('input').val('');
            $modal.find('.err').hide(); // Reset the errors on the modal
            $modal.find('.err').empty(); // Reset the errors on the modal
            $btnUserAdd.show();
            TogglePersonInfoBlock(!EnablePersonInfoBlock, !EnableInfoEditable); // Hide the block
            UpdateShareableMethods();
            $methodItem_SMS.addClass('color-unavailable');
            $methodItem_Email.addClass('color-unavailable');
            $methodItem_PDF.addClass('color-unavailable');
            $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
            $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
            $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
            SendingEmail = false;
            SendingSMS = false;
            SendingPDFPrint = false;
            SendingInPerson = false;
        });
        $btnUserAdd.on('click', function () {
            $btnUserAdd.hide();
            $peopleList.hide(); // Hide the last search results
            $searchInput.find('input').val('');
            $searchInput.hide(); // Hide the search bar
            $userBlock_blockTitle_back.show(); // Show the back btn
            EnableEmailShare = false;
            EnableSMSShare = false;
            $userBlock.find('.err').hide();
            $userBlock.find('.err').empty();
            TogglePersonInfoBlock(EnablePersonInfoBlock, !EnableInfoEditable); // Show the block
        });
        function SetupAPersonItem(p) {
            var emailPart = '';
            var mobilePart = '';
            if (p.Email) {
                emailPart = "<div><i class=\"fa fa-envelope\"></i> " + p.Email + "</div>";
            }
            if (p.Mobile) {
                mobilePart = "<div><i class=\"fa fa-phone\"></i> " + p.Mobile + "</div>";
            }
            if (!p.Email) {
                p.Email = '';
            }
            if (!p.Mobile) {
                p.Mobile = '';
            }
            if (!p.GivenNames) {
                p.GivenNames = '';
            }
            if (!p.DoB) {
                p.DoB = '';
            }
            if (!p.Hash) {
                p.Hash = '';
            }
            var element = $("<div class=\"person-item\">\n                    <input type=\"hidden\" name=\"fname\" value=\"" + p.FirstName + "\"/>\n                    <input type=\"hidden\" name=\"gname\" value=\"" + p.GivenNames + "\"/>\n                    <input type=\"hidden\" name=\"email\" value=\"" + p.Email + "\"/>\n                    <input type=\"hidden\" name=\"mobile\" value=\"" + p.Mobile + "\"/>\n                    <input type=\"hidden\" name=\"gender\" value=\"" + p.Gender + "\"/>\n                    <input type=\"hidden\" name=\"dob\" value=\"" + p.DoB + "\"/>\n                    <input type=\"hidden\" name=\"hash\" value=\"" + p.Hash + "\"/>\n                    <a class=\"cursor-pointer border-solid-1 border-radius-24 text-decoration-none\">\n                        <div>" + p.FirstName + " " + p.GivenNames + "</div>\n                        " + emailPart + "\n                        " + mobilePart + "\n                    </a>\n                </div>");
            element.find('a').on('click', function () {
                $btnUserAdd.hide();
                $peopleList.hide(); // Hide the last search results
                $userBlock.find('.err').hide(); // Reset the errors
                $userBlock.find('.err').empty();
                $searchInput.hide(); // Hide the search bar
                $searchInput.find('input').val(''); // We clear what they searched
                $userBlock_blockTitle_back.show(); // Show the back btn
                shareState.SelectedRecipient = {
                    FirstName: element.find('input[name="fname"]').val(),
                    GivenNames: element.find('input[name="gname"]').val(),
                    Email: element.find('input[name="email"]').val(),
                    Mobile: element.find('input[name="mobile"]').val(),
                    DoB: element.find('input[name="dob"]').val(),
                    Gender: element.find('input[name="gender"]').val(),
                    Hash: element.find('input[name="hash"]').val(),
                    isNew_FirstName: false,
                    isNew_GivenNames: false,
                    isNew_Email: false,
                    isNew_Mobile: false,
                    isNew_DoB: false,
                    isNew_Gender: false,
                    idx: p.idx
                };
                TogglePersonInfoBlock(EnablePersonInfoBlock, EnableInfoEditable, shareState.SelectedRecipient);
                UpdateShareableMethods();
            });
            return element;
        }
        function debounce(func, wait) {
            var timeout;
            return function () {
                var _this = this;
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                clearTimeout(timeout);
                timeout = setTimeout(function () { return func.apply(_this, args); }, wait);
            };
        }
        var DebounceGetInfo = debounce(function () {
            var $input = $(this).val();
            if (($input === null || $input === void 0 ? void 0 : $input.length) <= 0) {
                $peopleList.empty();
                $peopleList.hide();
                shareState.People = [];
                $userBlock.find('.err').empty();
                $userBlock.find('.err').hide();
            }
            if (($input === null || $input === void 0 ? void 0 : $input.length) >= 4) {
                if (shareState.ShareFeatureType == ShareFeature.ShareQRCode) {
                    // remove error message
                    $('.err.font-sm.alert.alert-danger').remove();
                    // check input is valid mobile or valid email
                    var mobileRegex = /^((04\d{8}|04\d{2}(\s)*\d{3}(\s)*\d{3})|(07\d{8}|07\d{2}(\s)*\d{3}(\s)*\d{3}))$/; // 04XXXXXXXX or 07XXXXXXXX
                    var emailRegex = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/;
                    var isValidMobile = mobileRegex.test($input);
                    var isValidEmail = emailRegex.test($input);
                    if (isValidMobile) {
                        $methodItem_SMS.removeClass('color-unavailable');
                        $methodItem_SMS.find('path').attr('fill', 'black');
                        $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', '#d14905');
                        _shareMethod = ShareMethod.SMS;
                        EnableSMSShare = true;
                    }
                    else {
                        $methodItem_SMS.addClass('color-unavailable');
                        $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
                        $methodItem_SMS.find('path').attr('fill', 'gray');
                        SendingSMS = false;
                        EnableSMSShare = false;
                    }
                    if (isValidEmail) {
                        $methodItem_Email.removeClass('color-unavailable');
                        $methodItem_Email.find('path').attr('fill', 'black');
                        $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', '#d14905');
                        _shareMethod = ShareMethod.Email;
                        EnableEmailShare = true;
                    }
                    else {
                        $methodItem_Email.addClass('color-unavailable');
                        $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
                        $methodItem_Email.find('path').attr('fill', 'gray');
                        SendingEmail = false;
                        EnableEmailShare = false;
                    }
                    if (!isValidMobile && !isValidEmail) {
                        _shareMethod = ShareMethod.None;
                    }
                }
                else {
                    var data = {
                        ProfileID: 0,
                        TimeStamp: 0,
                        Input: $input
                    };
                    $.ajax({
                        url: window.AppSettings.ApiUrl + "/api/v1/share/getinfo",
                        method: 'POST',
                        data: JSON.stringify(data),
                        dataType: 'json',
                        contentType: 'application/json',
                        success: function (res) {
                            $peopleList.empty();
                            if (res.success && res.data.length >= 1) {
                                //$peopleList.show();
                                var matches = res.data;
                                matches.forEach(function (match, i) {
                                    match.idx = i;
                                    shareState.People.push(match);
                                    $peopleList.append(SetupAPersonItem(match));
                                });
                            }
                            else {
                                $userBlock.find('.err').empty();
                                //$userBlock.find('.err').show();
                                $userBlock.find('.err').append('<i>Sorry, we could not find the person!</i>');
                            }
                        },
                        complete: function () { }
                    });
                }
            }
        }, 250);
        $userBlock.find('.search-input').find('input').on('input', DebounceGetInfo);
        // End of User Block
        // Method Block
        function CheckValidMobileString(s, isNew) {
            var _a, _b, _c, _d;
            // Trim the string first to remove any whitespace at the start or end
            s = s.trim();
            // Regular expression for the mobile number
            var mobileRegex = /^((04\d{8}|04\d{2}(\s)*\d{3}(\s)*\d{3})|(07\d{8}|07\d{2}(\s)*\d{3}(\s)*\d{3}))$/; // 04XXXXXXXX or 07XXXXXXXX
            return mobileRegex.test(s)
                || (((_b = (_a = shareState.SelectedRecipient) === null || _a === void 0 ? void 0 : _a.Hash) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((!isNew && ((_c = shareState.SelectedRecipient.Mobile) === null || _c === void 0 ? void 0 : _c.length) > 0 && ((_d = $userBlock_personInfo_Mobile.val()) === null || _d === void 0 ? void 0 : _d.length) > 0)
                    || (mobileRegex.test($userBlock_personInfo_Mobile.val())
                        && $userBlock_personInfo_Mobile.val() != shareState.SelectedRecipient.Mobile)));
        }
        //function FormatMobileString(s: string): string | null {
        //    // Remove all spaces
        //    s = s.replace(/\s/g, '');
        //    // Format the string
        //    return s.substring(0, 4) + ' ' + s.substring(4, 7) + ' ' + s.substring(7);
        //}
        function CheckValidEmailString(s, isNew) {
            var _a, _b, _c, _d;
            // Trim the string first to remove any whitespace at the start or end
            s = s.trim();
            // Regular expression for the email
            var emailRegex = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/;
            return emailRegex.test(s)
                || (((_b = (_a = shareState.SelectedRecipient) === null || _a === void 0 ? void 0 : _a.Hash) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((!isNew && ((_c = shareState.SelectedRecipient.Email) === null || _c === void 0 ? void 0 : _c.length) > 0 && ((_d = $userBlock_personInfo_Email.val()) === null || _d === void 0 ? void 0 : _d.length) > 0)
                    || (emailRegex.test($userBlock_personInfo_Email.val())
                        && $userBlock_personInfo_Email.val() != shareState.SelectedRecipient.Email)));
        }
        $methodItem_SMS.find('[class*="fa-floppy-disk"]').find('path').attr('fill', 'currentColor');
        $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
        $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
        $methodItem_SMS.on('click', function () {
            if (!$methodItem_SMS.hasClass('color-unavailable') && EnableSMSShare) {
                SendingSMS = (!SendingSMS || SendingEmail);
                if (SendingSMS) {
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', '#d14905');
                    SendingEmail = false;
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
                    SendingPDFPrint = false;
                    $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
                    SendingInPerson = false;
                    $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', 'currentColor');
                }
                else {
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
                }
            }
        });
        $methodItem_Email.on('click', function () {
            if (!$methodItem_Email.hasClass('color-unavailable') && EnableEmailShare) {
                SendingEmail = (!SendingEmail || SendingSMS);
                if (SendingEmail) {
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', '#d14905');
                    SendingSMS = false;
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
                    SendingPDFPrint = false;
                    $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
                    SendingInPerson = false;
                    $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', 'currentColor');
                }
                else {
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
                }
            }
        });
        $methodItem_PDF.on('click', function () {
            if (!$methodItem_PDF.hasClass('color-unavailable') && EnablePDFPrint) {
                SendingPDFPrint = !SendingPDFPrint;
                if (SendingPDFPrint) {
                    $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', '#d14905');
                    SendingSMS = false;
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
                    SendingEmail = false;
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
                    SendingInPerson = false;
                    $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', 'currentColor');
                }
                else {
                    $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
                }
            }
        });
        $methodItem_InPerson.on('click', function () {
            if (!$methodItem_InPerson.hasClass('color-unavailable') && EnableInPersonShare) {
                SendingInPerson = !SendingInPerson;
                if (SendingInPerson) {
                    $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', '#d14905');
                    SendingPDFPrint = false;
                    $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'currentColor');
                    SendingSMS = false;
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'currentColor');
                    SendingEmail = false;
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'currentColor');
                }
                else {
                    $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', 'currentColor');
                }
            }
        });
        function UpdateShareableMethods(ignoreSending) {
            if (ignoreSending === void 0) { ignoreSending = false; }
            // color-orange #d14905
            // color-unavailable is a logic class
            if (shareState.SelectedRecipient) {
                if (!CheckValidMobileString(shareState.SelectedRecipient.Mobile, shareState.SelectedRecipient.isNew_Mobile)) {
                    $methodItem_SMS.addClass('color-unavailable');
                    $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', 'black');
                    $methodItem_SMS.find('path').attr('fill', 'gray');
                    SendingSMS = false;
                    EnableSMSShare = false;
                }
                else {
                    $methodItem_SMS.removeClass('color-unavailable');
                    $methodItem_SMS.find('path').attr('fill', 'black');
                    EnableSMSShare = true;
                }
                if (!CheckValidEmailString(shareState.SelectedRecipient.Email, shareState.SelectedRecipient.isNew_Email)) {
                    $methodItem_Email.addClass('color-unavailable');
                    $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', 'black');
                    $methodItem_Email.find('path').attr('fill', 'gray');
                    SendingEmail = false;
                    EnableEmailShare = false;
                }
                else {
                    $methodItem_Email.removeClass('color-unavailable');
                    $methodItem_Email.find('path').attr('fill', 'black');
                    EnableEmailShare = true;
                }
                if (ignoreSending) {
                    SendingEmail = false;
                    SendingSMS = false;
                }
                //
                EnableInPersonShare = true;
                $methodItem_InPerson.removeClass('color-unavailable');
                $methodItem_InPerson.find('path').attr('fill', 'black');
                EnablePDFPrint = true;
                $methodItem_PDF.removeClass('color-unavailable');
                $methodItem_PDF.find('path').attr('fill', 'black');
                //
            }
            else { // this condition might not be reached
                EnableEmailShare = false;
                EnableSMSShare = false;
                EnableInPersonShare = false;
                EnablePDFPrint = false;
                $methodItem_InPerson.addClass('color-unavailable');
                $methodItem_PDF.addClass('color-unavailable');
                $methodItem_InPerson.find('[class*="fa-users"]').find('path').attr('fill', 'black');
                $methodItem_InPerson.find('path').attr('fill', 'gray');
                $methodItem_PDF.find('[class*="fa-print"]').find('path').attr('fill', 'black');
                $methodItem_PDF.find('path').attr('fill', 'gray');
            }
            if (EnableEmailShare && !EnableSMSShare /*&& !SendingPDFPrint*/) {
                $methodItem_Email.find('[class*="fa-envelope"]').find('path').attr('fill', '#d14905');
                SendingEmail = true;
            }
            else if (EnableSMSShare && !EnableEmailShare /*&& !SendingPDFPrint*/) {
                $methodItem_SMS.find('[class*="fa-comments"]').find('path').attr('fill', '#d14905');
                SendingSMS = true;
            }
        }
        // End of Method Block
        // Content/Info Block
        $contentNonlisted_Mobile.on('input change', function () {
            if (shareState.Content.Nonlisted) {
                shareState.Content.Nonlisted.Mobile = $(this).val();
            }
        });
        //$contentNonlisted_Email.on('input change', function () {
        //    if (shareState.Content.Nonlisted) {
        //        shareState.Content.Nonlisted.Email = $(this).val();
        //    }
        //});
        $contentNonlisted_Website.on('input change', function () {
            if (shareState.Content.Nonlisted) {
                shareState.Content.Nonlisted.Website = $(this).val();
            }
        });
        $contentTileShare_Tile.on('input change', function () {
            if (shareState.Content.TileShare) {
                shareState.Content.TileShare.Tile.ID = $(this).val();
                shareState.Content.TileShare.Tile.Name = $(this).text();
            }
        });
        //$contentTileShare_Suburb.on('input change', function () {
        //    if (shareState.Content.TileShare) {
        //        shareState.Content.TileShare.Suburb = $(this).val();
        //    }
        //});
        //$contentTileShare_Street.on('input change', function () {
        //    if (shareState.Content.TileShare) {
        //        shareState.Content.TileShare.Street = $(this).val();
        //    }
        //});
        //$contentTileShare_Funding_NDIS.on('change', function () {
        //    if (this.checked && shareState.Content.TileShare) {
        //        $contentTileShare_Funding_NoFunding.prop('checked', false).change();
        //        shareState.Content.TileShare.IsFundedByNDIS = true;
        //    } else {
        //        shareState.Content.TileShare.IsFundedByNDIS = false;
        //    }
        //});
        //$contentTileShare_Funding_MyAgedCare.on('change', function () {
        //    if (this.checked && shareState.Content.TileShare) {
        //        $contentTileShare_Funding_NoFunding.prop('checked', false).change();
        //        shareState.Content.TileShare.IsFundedByMyAgedCare = true;
        //    } else {
        //        shareState.Content.TileShare.IsFundedByMyAgedCare = false;
        //    }
        //});
        //$contentTileShare_Funding_NoFunding.on('change', function () {
        //    if (this.checked && shareState.Content.TileShare) {
        //        shareState.Content.TileShare.IsFundedByEmploymentServices = false;
        //        shareState.Content.TileShare.IsFundedByMyAgedCare = false;
        //        shareState.Content.TileShare.IsFundedByNDIS = false;
        //        $contentTileShare_Funding_NDIS.prop('checked', false).change();
        //        $contentTileShare_Funding_MyAgedCare.prop('checked', false).change();
        //    }
        //});
        //$contentTileShare_Funding_Assistance.on('change', function () {
        //    let checkedValue = $('input[type="radio"][name="assistance"]:checked').val();
        //    if (shareState.Content.TileShare) {
        //        shareState.Content.TileShare.LinkageAssistanceLevel = checkedValue;
        //    }
        //})
        //$contentTileShare_Agree.on('change', function () {
        //    if (this.checked) {
        //        shareState.Content.TileShare.Agree = true;
        //    } else {
        //        shareState.Content.TileShare.Agree = false;
        //    }
        //});
        $contentFavoriteShare_ShareAs.on('change', function () {
            if (shareState.Content.FavoriteShare) {
                shareState.Content.FavoriteShare.ShareAs = $(this).val();
            }
        });
        //
        // Submit
        function AppendErrorMsg(e, message) {
            var div = "<div class=\"err font-sm alert alert-danger\"><i>" + message + "</i></div>";
            e.append(div);
        }
        $btnSubmit.on('click', function () {
            var _a, _b, _c, _d;
            if (shareState.ShareFeatureType == ShareFeature.ShareQRCode) {
                // check isvalid email or isvalid mobile
                if ((!EnableSMSShare || !EnableEmailShare) && _shareMethod == ShareMethod.None) {
                    AppendErrorMsg($modal.find('.share-modal-body'), "Please provide valid email or mobile number");
                    return;
                }
                // prepare request model
                var request = {
                    ShareFeatureType: shareState.ShareFeatureType,
                    Method: _shareMethod,
                    Recipient: $searchInput.find('input').val(),
                    Content: {
                        Title: shareState.Content.Title,
                        Message: shareState.Content.Message,
                        PageUrl: shareState.Content.PageUrl,
                        CategoryGroup: shareState.Content.CategoryGroup,
                        Nonlisted: shareState.Content.Nonlisted,
                        TileShare: shareState.Content.TileShare,
                        FavoriteShare: shareState.Content.FavoriteShare
                    }
                };
                // send request
                $.ajax({
                    url: window.AppSettings.ApiUrl + "/api/v1/share/shareqrcode",
                    method: 'POST',
                    data: JSON.stringify(request),
                    dataType: 'json',
                    contentType: 'application/json',
                    success: function (res) {
                        $modal.find('.err').hide(); // Reset the errors on the modal
                        $modal.find('.err').empty(); // Reset the errors on the modal
                        $modal.modal('hide');
                        if (res.messages) {
                            console.log("res.messages", res.messages);
                            if (res.messages[0].index == -1) {
                                $.notify({
                                    title: "<strong>Fail!</strong>",
                                    message: res.messages[0].message,
                                }, {
                                    type: 'danger',
                                    placement: {
                                        from: "top",
                                        align: "right"
                                    },
                                });
                            }
                            else {
                                $.notify({
                                    title: "<strong>Success!</strong>",
                                    message: res.messages[0].message,
                                }, {
                                    type: 'success',
                                    placement: {
                                        from: "top",
                                        align: "right"
                                    },
                                });
                            }
                        }
                    },
                    complete: function () { }
                });
            }
            else {
                // pre request validation
                if (!shareState.SelectedRecipient) { // just to be safe
                    AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Recipient!");
                    return;
                }
                if (!shareState.SelectedRecipient.FirstName) {
                    AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Recipient First Name!");
                    return;
                }
                //
                var request = {
                    ShareFeatureType: shareState.ShareFeatureType,
                    Method: ShareMethod.None,
                    Recipient: {
                        FirstName: shareState.SelectedRecipient.FirstName,
                        GivenNames: shareState.SelectedRecipient.GivenNames,
                        Email: shareState.SelectedRecipient.Email,
                        Mobile: shareState.SelectedRecipient.Mobile,
                        Gender: shareState.SelectedRecipient.Gender,
                        DoB: shareState.SelectedRecipient.DoB,
                        Hash: shareState.SelectedRecipient.Hash,
                    },
                    Content: {
                        Title: shareState.Content.Title,
                        Message: shareState.Content.Message,
                        PageUrl: shareState.Content.PageUrl,
                        CategoryGroup: shareState.Content.CategoryGroup,
                        Nonlisted: shareState.Content.Nonlisted,
                        TileShare: shareState.Content.TileShare,
                        FavoriteShare: shareState.Content.FavoriteShare
                    }
                };
                if (SendingEmail) {
                    request.Method = ShareMethod.Email;
                }
                else if (SendingSMS) {
                    request.Method = ShareMethod.SMS;
                }
                else if (SendingPDFPrint) {
                    request.Method = ShareMethod.PDF;
                }
                else if (SendingInPerson) {
                    request.Method = ShareMethod.InPerson;
                }
                else {
                    request.Method = ShareMethod.None;
                }
                // request validation
                switch (request.Method) {
                    case ShareMethod.Email:
                        if (!CheckValidEmailString(request.Recipient.Email)) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "Please provide a valid email!");
                            return;
                        }
                        break;
                    case ShareMethod.SMS:
                        if (!CheckValidMobileString(request.Recipient.Mobile)) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "Please provide a valid phone number!");
                            return;
                        }
                        break;
                    case ShareMethod.PDF:
                        if (request.ShareFeatureType != ShareFeature.RecordCommunication) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "This share method is not available for the current share option!");
                            return;
                        }
                        break;
                    case ShareMethod.InPerson:
                        if (request.ShareFeatureType != ShareFeature.RecordCommunication) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "This share method is not available for the current share option!");
                            return;
                        }
                        break;
                    case ShareMethod.None:
                    default:
                        AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Share Method. Please select either Email or SMS option!");
                        return;
                }
                switch (request.ShareFeatureType) {
                    case ShareFeature.ShareATile:
                        if (!request.Content.TileShare || !((_a = request.Content.TileShare) === null || _a === void 0 ? void 0 : _a.Tile) || ((_c = (_b = request.Content.TileShare) === null || _b === void 0 ? void 0 : _b.Tile) === null || _c === void 0 ? void 0 : _c.ID) <= 0) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Tile Share!");
                            return;
                        }
                        request.Content.Title = request.Content.TileShare.Tile.Name;
                        break;
                    case ShareFeature.ShareFavorites:
                        if (!request.Content.FavoriteShare || ((_d = request.Content.FavoriteShare) === null || _d === void 0 ? void 0 : _d.FavoriteID) <= 0) {
                            AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Favorite Share!");
                            return;
                        }
                        request.Content.Title = request.Content.FavoriteShare.FavoriteName;
                        break;
                    case ShareFeature.ShareAListing:
                    case ShareFeature.ShareANote:
                    case ShareFeature.ShareNonlisted:
                    case ShareFeature.RecordCommunication:
                        break;
                    case ShareFeature.None:
                    default:
                        AppendErrorMsg($modal.find('.share-modal-body'), "Invalid Share Feature Type!");
                        return;
                }
                // Send
                $.ajax({
                    url: window.AppSettings.ApiUrl + "/api/v1/share/post",
                    method: 'POST',
                    data: JSON.stringify(request),
                    dataType: 'json',
                    contentType: 'application/json',
                    success: function (res) {
                        $modal.find('.err').hide(); // Reset the errors on the modal
                        $modal.find('.err').empty(); // Reset the errors on the modal
                        if (res.messages) {
                            AppendErrorMsg($modal.find('.share-modal-body'), res.messages[0].message);
                        }
                        else {
                            $modal.modal('hide');
                            // Reset fields
                        }
                    },
                    complete: function () { }
                });
            }
        });
        return { changeFavoriteShare: UpdateFavoriteShare };
    }
    Common.SetupShareModal = SetupShareModal;
    function SetupTooltipsStyling() {
        $(document).on('mouseover', '[title]', function (e) {
            if (typeof $(this).qtip === "function") {
                $(this).qtip({
                    overwrite: false,
                    content: $(this).attr('title'),
                    show: {
                        event: e.type,
                        ready: true
                    },
                    position: {
                        my: "bottom right",
                        at: "top right"
                    },
                    style: {
                        classes: 'qtip-dark qtip-shadow qtip-rounded'
                    },
                    events: {
                        show: function (event, api) {
                            var $el = $(api.elements.target[0]);
                            $el.qtip('option', 'position.my', ($el.data('tooltip-my') === undefined) ? 'bottom right' : $el.data('tooltip-my'));
                            $el.qtip('option', 'position.at', ($el.data('tooltip-at') === undefined) ? 'top right' : $el.data('tooltip-at'));
                        }
                    }
                }, e);
                $(this).attr('oldtitle', $(this).attr('title'));
                $(this).removeAttr('title');
            }
        });
    }
    Common.SetupTooltipsStyling = SetupTooltipsStyling;
    var loadConverters = true;
    function SetupConverters() {
        if (loadConverters) {
            loadConverters = false;
            $.views.converters({
                tel: function (value) {
                    value = value || "";
                    var telValue = value.replace(/[^0-9]/g, "");
                    var accessibleValue = telValue.replace(/([0-9])/g, "$1 ");
                    if (telValue.length > 0) {
                        return "<p class='icon icon-phone60'><a href='tel:" + telValue + "' aria-label='Telephone number: " + accessibleValue + "'>" + value + "</a></p>";
                    }
                    else if (value.substr(0, 2) === "--") {
                        return "<p class='icon icon-phone60' aria-label='Telephone number: withheld' tabindex='0'>" + value + "</p>";
                    }
                    else {
                        return "";
                    }
                },
                map: function (value) {
                    value = value || "";
                    var inner = "--not supplied--";
                    if (value.length > 0) {
                        if (value.substr(0, 2) === "--") {
                            inner = value;
                        }
                        else {
                            inner = "<a href='https://maps.google.com/?q=" + encodeURIComponent(value) + "' target='_blank' aria-label='Address: " + value + "'>" + value + "</a>";
                        }
                    }
                    return "<p class='icon icon-map15'>" + inner + "</p>";
                },
                email: function (value) {
                    value = value || "";
                    if (value.indexOf("@") >= 0) {
                        return "<p class='icon icon-new48'><a href='mailto:" + value + "' aria-label='Email: " + value + "' >" + value + "</a></p>";
                    }
                    else {
                        return "";
                    }
                }
            });
        }
    }
    Common.SetupConverters = SetupConverters;
    function DisableFilter() {
        if ($(".btn-filter.disabled").length) {
            return;
        }
        $("#btn-apply").attr("disabled", "disabled");
        $(".btn-filter").addClass("disabled");
        $(".btn-all-results, .btn-local-results").addClass("disabled");
        $(".btn-distance, .btn-alpha").attr("disabled", "disabled");
        $(".btn-distance, .btn-alpha").on("click touchend", disableRank);
    }
    Common.DisableFilter = DisableFilter;
    function ReenableFilter() {
        $("#btn-apply").removeAttr("disabled");
        $(".btn-filter").removeClass("disabled");
        $(".btn-all-results, .btn-local-results").removeClass("disabled");
        $(".btn-distance, .btn-alpha").removeAttr("disabled");
        $(".btn-distance, .btn-alpha").off("click touchend", disableRank);
    }
    Common.ReenableFilter = ReenableFilter;
    function disableRank(ev) {
        ev.preventDefault();
    }
    // Cookies
    function SetCookie(cookieName, cookieValue, expiryDays, expiryHours, expiryMinutes, expirySeconds) {
        if (expiryDays === void 0) { expiryDays = 0; }
        if (expiryHours === void 0) { expiryHours = 0; }
        if (expiryMinutes === void 0) { expiryMinutes = 0; }
        if (expirySeconds === void 0) { expirySeconds = 0; }
        var d = new Date();
        d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000) + (expiryHours * 60 * 60 * 1000) + (expiryMinutes * 60 * 1000) + (expirySeconds * 1000));
        var expires = "expires=" + d.toUTCString() + ";";
        if (expiryDays + expiryHours + expiryMinutes + expirySeconds === 0) {
            expires = 'expires="";';
        }
        document.cookie = cookieName + "=; expires=Wed, 01 Jan 1970; path=/; secure=true;";
        document.cookie = cookieName + "=" + cookieValue + "; " + expires + " path=/; secure=true;";
    }
    Common.SetCookie = SetCookie;
    function GetCookie(cookieName) {
        var name = cookieName + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1);
            if (c.indexOf(name) === 0)
                return c.substring(name.length, c.length);
        }
        return "";
    }
    Common.GetCookie = GetCookie;
    function GetCouncil(id, saveCouncilCallback) {
        if (id === void 0) { id = 0; }
        if (id === 0 || saveCouncilCallback === null)
            return;
        $.getJSON(window.AppSettings.ApiUrl + "/api/v1/system/councils/" + id, function (data) {
            saveCouncilCallback(data);
        });
    }
    Common.GetCouncil = GetCouncil;
    function SetLocationCookie(location) {
        SetCookie("Mcd.Location", "Council:" + JSON.stringify(location.Council) + "|ID:" + location.ID + "|Name:" + location.Name, 365);
    }
    function GetLocationCookie(location) {
        var cookie = GetCookie("Mcd.Location");
        if (cookie !== "") {
            var parts = cookie.split('|');
            for (var i = 0; i < parts.length; i++) {
                if (parts[i].substr(0, 8) === "Council:") {
                    location.Council = JSON.parse(parts[i].substr(8));
                }
                else if (parts[i].substr(0, 3) === "ID:") {
                    location.ID = parseInt(parts[i].substr(3));
                }
                else if (parts[i].substr(0, 5) === "Name:") {
                    location.Name = parts[i].substr(5);
                }
            }
        }
        return location;
    }
    $("#btn-cross").off("click").on("click", function (e) {
        e.preventDefault();
        SetCookie("Mcd.LocationNag", "Declined");
        hidebanner();
    });
    function RequestLocation(destUrl, locationPrefix, wherePrefix, locationId, locationName) {
        if (destUrl === void 0) { destUrl = ""; }
        if (locationPrefix === void 0) { locationPrefix = "?id="; }
        if (wherePrefix === void 0) { wherePrefix = "&location="; }
        if (locationId === void 0) { locationId = 0; }
        if (locationName === void 0) { locationName = ""; }
        $(function () {
            var location = {};
            location.DestinationUrl = destUrl;
            location.LocationUrlPrefix = locationPrefix;
            location.WhereUrlPrefix = wherePrefix;
            location.Council = null;
            location.ID = locationId;
            location.Name = locationName;
            if (location.ID > 0 || location.Name !== "") {
                // Pass through here when the user clicked on the Search button and has supplied a location
                gotoLocation(location);
                hidebanner();
                return;
            }
            setPanelContents(PanelContents.None, location);
            var offerHelp = GetCookie("Mcd.LocationNag");
            if (offerHelp === "Declined") {
                // Pass through here when the user has said No Thanks
                if (location.DestinationUrl !== "") {
                    window.location.href = location.DestinationUrl;
                }
                hideLocationPanel();
                return;
            }
            location = GetLocationCookie(location);
            if (location.Council != null) {
                // Show the user where we think they previously searched
                setPanelContents(PanelContents.Previous, location);
            }
            else {
                RequestBrowserLocation(location);
            }
            SetCookie("Mcd.LocationNag", "Declined");
            if (location && location.DestinationUrl) {
                window.location.href = location.DestinationUrl;
            }
            // When a location is selected from autocomplete, adjust council properties and update cookie
            $("#location-request").on("typeahead:selected", function (obj, datum) {
                $.getJSON("/api/locations/id/" + datum.LocalityID, function (data) {
                    if (data) {
                        location.ID = datum.LocalityID;
                        location.Name = data.Name + " " + data.Postcode;
                        location.Council = {
                            CouncilRegionID: data.CouncilRegionID,
                            DisplayName: data.CouncilDisplayName,
                            Url: data.CouncilURL
                        };
                        SetLocationCookie(location);
                    }
                    else {
                        Common.Alert("<p>We were unable to successfully determine the location you wish to search in. Please be more specific with your location or select an item from the list.</p>", "");
                    }
                });
            });
        });
    }
    Common.RequestLocation = RequestLocation;
    function gotoLocation(location) {
        if (location.ID != 0) {
            if (location.Name === "") {
                $.getJSON("/api/locations/id/" + location.ID, function (data) {
                    if (data) {
                        location.Name = data.Name + " " + data.Postcode;
                        location.Council = {
                            CouncilRegionID: data.CouncilRegionID,
                            DisplayName: data.CouncilDisplayName,
                            Url: data.CouncilURL
                        };
                        checkCouncilandProceed(location);
                    }
                    else {
                        Common.Alert("<p>We were unable to successfully determine the location you wish to search in. Please be more specific with your location or select an item from the list.</p>", "");
                    }
                });
            }
            else {
                checkCouncilandProceed(location);
            }
        }
        else {
            if (location.Name !== "") {
                $.ajax({
                    type: "GET",
                    //url: `/api/locations/${location.Lat}/${location.Lng}`,
                    url: "/api/locations/" + location.Name,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (data) {
                        if (data) {
                            location.ID = data.LocalityID;
                            location.Name = data.Name + " " + data.Postcode;
                            location.Council = {
                                CouncilRegionID: data.CouncilRegionID,
                                DisplayName: data.CouncilDisplayName,
                                Url: data.CouncilURL
                            };
                            checkCouncilandProceed(location);
                        }
                        else {
                            Common.Alert("<p>We were unable to successfully determine the location you wish to search in. Please be more specific with your location or select an item from the list.</p>", "");
                        }
                    }
                });
            }
            else {
                Common.Alert("<p>The directory works best when we have a location to search in. Please specify a location.</p>", "");
            }
        }
    }
    function checkCouncilandProceed(location) {
        if (!location.Council || location.Council.CouncilRegionID == 0) {
            $.getJSON("/api/councils/bylocalityid/" + location.ID, function (data) {
                if (data && data.Loaded) {
                    location.Council = data;
                    location.DestinationUrl = location.DestinationUrl || data.Url;
                    SetLocationCookie(location);
                    window.location.href = location.DestinationUrl + ("" + location.LocationUrlPrefix + location.ID + location.WhereUrlPrefix + encodeURIComponent(location.Name));
                }
                else {
                    Common.Alert("<p>We were unable to successfully determine the location you wish to search in. Please be more specific with your location or select an item from the list.</p>", "");
                }
            });
            return;
        }
        var destUrl = "";
        if (location.DestinationUrl) {
            destUrl = location.DestinationUrl;
        }
        else {
            destUrl = location.Council.Url + ("" + location.LocationUrlPrefix + location.ID + location.WhereUrlPrefix + encodeURIComponent(location.Name));
        }
        location.DestinationUrl = location.DestinationUrl || location.Council.Url;
        SetLocationCookie(location);
        window.location.href = destUrl;
    }
    function hideLocationPanel() {
        $("#location-request-panel").slideUp('slow');
        $("#location-request-blind").hide();
    }
    function hidebanner() {
        $("#virus-banner").slideUp('slow');
        $("#virus-banner").hide();
    }
    function RequestBrowserLocation(location) {
        if (window.navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                findCouncilandLocation(position, location);
            }, function (error) {
                console.log("Geolocation Error: ", error);
            }, { enableHighAccuracy: true });
        }
    }
    Common.RequestBrowserLocation = RequestBrowserLocation;
    function setPanelContents(content, location) {
        $(".locality-id").val(location.ID);
        $("#location-request").typeahead('val', location.Name);
        switch (content) {
            case PanelContents.None:
                $("#location-request-panel .inner .blurb").html("<p>Want better results? <br /><strong>Tell us your location to refine your search</strong> <br />to help us find listings near you?</p>");
                break;
            case PanelContents.Previous:
                $("#location-request-panel .inner .blurb").html("<p>Previously you searched near <span>" + location.Name + "</span><br /><strong>Would you like to refine your search</strong><br />to listings in <span>" + location.Council.DisplayName + "</span>?</p>");
                break;
            case PanelContents.Located:
                $("#location-request-panel .inner .blurb").html("<p>It looks like you're near <span>" + location.Name + "</span><br /><strong>Would you like to refine your search</strong><br />to listings in <span>" + location.Council.DisplayName + "</span>?</p>");
                break;
        }
        $("#btn-go-location").off("click").on("click", function (e) {
            e.preventDefault();
            location.ID = parseInt($("#hfLocationRequestId").val());
            location.Name = $("#location-request").val();
            if (location.Name === '') {
                Common.Alert("<p>The directory works best when we have a location to search in. Please specify a location.</p>", "");
                return;
            }
            //else if (location.ID > 0) {
            //    console.log(location.Name);
            //    console.log(location.ID);
            //    debugger;
            //    $.getJSON(`/api/Locations/id/${location.ID}`,
            //        function (data) {
            //            location.DestinationUrl = `${data.CouncilURL}/${data.LocationUrl}`;
            //            gotoLocation(location);
            //        });
            //    debugger;
            //}
            else {
                gotoLocation(location);
            }
        });
    }
    function findCouncilandLocation(position, location) {
        $.getJSON("/api/Councils/" + position.coords.latitude + "/" + position.coords.longitude, function (data) {
            if (data.Loaded) {
                location.Council = data;
                findLocation(position, location);
            }
        });
    }
    function findLocation(position, location) {
        $.getJSON("/api/Locations/" + position.coords.latitude + "/" + position.coords.longitude, function (data) {
            if ((data.LocalityID || 0) > 0 || (data.Name || '') !== '') {
                location.ID = data.LocalityID;
                location.Name = data.Name + " " + data.Postcode;
                setPanelContents(PanelContents.Located, location);
            }
        });
    }
    function Alert(text, title, okCallback, okLabel) {
        if (okCallback === void 0) { okCallback = null; }
        if (okLabel === void 0) { okLabel = "OK"; }
        title = title || "Alert";
        var id = 'autoAlertModal';
        $("body").remove("#" + id);
        var modal = $('\
<div id=' + id + ' class="modal fade" tabindex="-1" role="dialog"> \
    <div class="modal-dialog" role="document"> \
        <div class="modal-content"> \
            <form method="post" action=""> \
                <div class="modal-header"> \
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> \
                    <h4 class="modal-title">' + title + '</h4> \
                </div> \
                <div class="modal-body">' + text + '</div> \
                <div class="modal-footer"> \
                    <button type="button" class="btn btn-default modal-ok" data-dismiss="modal">' + okLabel + '</button> \
                </div> \
            </form> \
        </div> \
    </div> \
</div>');
        var hadAction = false;
        modal.on("click", ".modal-ok", function (e) {
            e.preventDefault();
            hadAction = true;
            if (okCallback)
                okCallback();
        });
        modal.on('hidden.bs.modal', function () {
            modal.remove();
            if (!hadAction) {
                hadAction = true;
                if (okCallback)
                    okCallback();
            }
        });
        modal.modal('show');
    }
    Common.Alert = Alert;
    function FormatAsAusPhone(field, state) {
        var value = $.trim($(field).val());
        if ((value || "").length > 0) {
            var re = new RegExp(/^04\d{2}\s\d{3}\s\d{3}$|^\(\d{2}\)\s\d{4}\s\d{4}$|^13(\s\d{2}){2,3}$|^(1300|1800)\s\d{3}\s\d{3}$/);
            if (!value.match(re)) {
                var newValue;
                var trimValue = value.replace(/[^0-9]/g, '');
                var mobileRe = new RegExp(/^04\d{8}$/);
                if (trimValue.match(mobileRe)) {
                    newValue = trimValue.substr(0, 4) + " " + trimValue.substr(4, 3) + " " + trimValue.substr(7, 3);
                    $(field).val(newValue);
                    return;
                }
                var short13Re = new RegExp(/^13\d{4}$/);
                if (trimValue.match(short13Re)) {
                    newValue = trimValue.substr(0, 2) + " " + trimValue.substr(2, 2) + " " + trimValue.substr(4, 2);
                    $(field).val(newValue);
                    return;
                }
                var long13Re = new RegExp(/^13\d{6}$/);
                if (trimValue.match(long13Re)) {
                    newValue = trimValue.substr(0, 2) + " " + trimValue.substr(2, 2) + " " + trimValue.substr(4, 2) + " " + trimValue.substr(6, 2);
                    $(field).val(newValue);
                    return;
                }
                var long13Or1800Re = new RegExp(/^(1300|1800)\d{6}$/);
                if (trimValue.match(long13Or1800Re)) {
                    newValue = trimValue.substr(0, 4) + " " + trimValue.substr(4, 3) + " " + trimValue.substr(7, 3);
                    $(field).val(newValue);
                    return;
                }
                var simpleNational = new RegExp(/^0[2378]\d{8}$/);
                if (trimValue.match(simpleNational)) {
                    newValue = "(" + trimValue.substr(0, 2) + ") " + trimValue.substr(2, 4) + " " + trimValue.substr(6, 4);
                    $(field).val(newValue);
                    return;
                }
                var local = new RegExp(/^\d{8}$/);
                if (trimValue.match(local) && state) {
                    if (state === "QLD") {
                        newValue = "(07) " + trimValue.substr(0, 4) + " " + trimValue.substr(4, 4);
                        $(field).val(newValue);
                        return;
                    }
                    else if (state === "NSW" || state === "ACT") {
                        newValue = "(02) " + trimValue.substr(0, 4) + " " + trimValue.substr(4, 4);
                        $(field).val(newValue);
                        return;
                    }
                    else if (state === "VIC" || state === "TAS") {
                        newValue = "(03) " + trimValue.substr(0, 4) + " " + trimValue.substr(4, 4);
                        $(field).val(newValue);
                        return;
                    }
                    else if (state === "WA" || state === "SA" || state === "NT") {
                        newValue = "(08) " + trimValue.substr(0, 4) + " " + trimValue.substr(4, 4);
                        $(field).val(newValue);
                        return;
                    }
                }
            }
        }
    }
    Common.FormatAsAusPhone = FormatAsAusPhone;
})(Common || (Common = {}));
